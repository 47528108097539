.ant-card.notification-card{
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0;
  transition: background-color 0.5s;
  .ant-card-body {
    display: flex;
    align-items: baseline;
  }
  .notification-card-content {
    text-align: left;
  }
  &.notification-card-clicked {
    background-color: rgba(100, 100, 100, 0.25);
  }
  &:last-child {
    margin-bottom: 0;
  }
}
@primary-color: #454f58;