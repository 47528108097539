@import '~antd/lib/style/themes/default.less';

div.menuTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 64px;
  margin: 0 @margin-lg;
  transition: all 0.2s;
  > .trigger {
    display: flex;
    background-color: transparent;
    width: auto;
    height: auto;
    cursor: pointer;
    transition: color 0.3s;
    color: @black;
    padding: 0;
  }
  > .trigger:hover {
    color: @primary-color;
  }
}

@primary-color: #454f58;