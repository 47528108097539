.notification-popover-overlay {
  max-width: 85vw;
  max-height: 75vh;
  .ant-popover-content {
    max-height: 75vh;
    .ant-popover-inner {
      border-radius: 0.5rem;
      max-height: 75vh;
      overflow: scroll;
      overflow-x: hidden;
      .ant-popover-inner-content {
        padding: 0.5rem;
      }
      scrollbar-width: auto;
      scrollbar-color: #dfc4b9 transparent;
    }
    /* Chrome, Edge, and Safari */
    .ant-popover-inner::-webkit-scrollbar {
      width: 5px;
      margin-left: 19px;
    }

    .ant-popover-inner::-webkit-scrollbar-track {
      background: white;
    }

    .ant-popover-inner::-webkit-scrollbar-thumb {
      background-color: #dfc4b9;
      border-radius: 15px;
      border: none;
    
    }
    .notifications-popover {
      min-width: 30vw;
      text-align: center;
    }
  }
}
@primary-color: #454f58;