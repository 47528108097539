@import '~antd/lib/style/themes/default.less';

header.HeaderComponent {
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(168, 60, 22, 1) 0%,
    rgba(72, 61, 58, 1) 100%
  );
  height: auto;
  color: white;
  padding: 0 30px;
  .menuTrigger,
  .HeaderComponent__container-logo,
  .HeaderComponent__container-buttons {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .menuTrigger {
    > .trigger {
      margin-right: auto;
    }
  }
  .HeaderComponent__container-buttons {
    > div {
      white-space: nowrap;
      margin-left: auto;
    }
  }
  .HeaderText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  > .HeaderComponent__container-buttons {
    display: flex;
    align-items: center;
  }
  > div.AvatarComponent {
    .Avatar {
      transform: translateY(-2px);
    }
    .AvatarName {
      padding-left: 6px;
      color: @text-color;
    }
  }
  > .HeaderComponent__container-quantity-of-trips-confirmed {
    padding: 0 15px 0 0;
    display: flex;
    align-items: center;
  }
}

@primary-color: #454f58;