@import '~antd/lib/style/themes/default.less';

.RegisteredLayout {
  > aside.ant-layout-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    white-space: nowrap;
    z-index: 6;
  }
  > aside.ant-layout-sider::-webkit-scrollbar {
    width: 6px;
  }
  > aside.ant-layout-sider::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }
  > aside.ant-layout-sider::-webkit-scrollbar-thumb {
    background-color: #a07364; /* color of the scroll thumb */
    border-radius: 50px; /* roundness of the scroll thumb */
    border: none; /* creates padding around scroll thumb */
  }
  > section.ant-layout {
    color: inherit;
    height: 100vh;
    overflow-y: auto;
    > .backdrop-content {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.52);
    }
    > .ant-layout-content {
      overflow: auto;
      overflow-x: hidden;
      padding: 24px 0 24px;

      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      -webkit-overflow-scrolling: touch;
      scrollbar-width: auto;
      scrollbar-color: #a07364 transparent;
    }
    /* Chrome, Edge, and Safari */
    .ant-layout-content::-webkit-scrollbar {
      width: 5px;
      margin-left: 19px;
    }

    .ant-layout-content::-webkit-scrollbar-track {
      background: transparent;
    }

    .ant-layout-content::-webkit-scrollbar-thumb {
      background-color: #a07364;
      border-radius: 15px;
      border: none;
    }
    transition: all 0.2s;
  }
}

@primary-color: #454f58;